.date-time-input-block {
    display: flex;
    flex-direction: column;
    /*margin: 0.5rem;*/
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.date-time-actions-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    gap: 8px;
}

.rmdp-wrapper {
    /*position: absolute;*/
    bottom: 60px;
    width: max-content;
    align-self: center;
}

.rmdp-top-class {
    color: black;
}


.date-time-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    width: 100%;
    font-size: 16px;
}

.date-time-suggestions {
    position: absolute;
    bottom: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    min-height: fit-content;
    z-index: 1;
}

.date-time-suggestion {
    color: black;
    font-size: 16px;
    padding: 0.5rem;
    margin: 0;
    border-bottom: 1px solid #ccc;
    text-align: left;
    cursor: pointer;
}

.date-time-suggestion:hover {
    background-color: #f0f0f0;
}

.visible {
    display: block;
}

.hidden {
    display: none;
    width: 0;
}