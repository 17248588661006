.vgt-button {
    background-color: #373737;
    border: none;
    color: white;
    padding: 7px 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.vgt-button:hover {
    filter: brightness(90%) !important;
}

.vgt-button-primary {
    background-color: #862633;
    border: 1px solid #862633;
}

.vgt-button-tertiary {
    background-color: #ffffff;
    color: black;

    border: 1px solid rgba(0, 0, 0, 0.23);
}

.vgt-button-secondary {
    background-color: #ffffff;
    color: #862633;

    border: 1px solid #862633;
}

.vgt-button-full {
    width: 100%;
}

.vgt-button-auto {
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.vgt-button:disabled {
    cursor: not-allowed;
    opacity: 50%;
}

/*.vgt-button-secondary:hover {*/
/*    background-color: #373737;*/
/*}*/