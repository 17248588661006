:root {
    --rmdp-primary-debc: #862633;
    --rmdp-secondary-debc: #862633;
    --rmdp-shadow-debc: rgba(134, 38, 51, 0.66);
    --rmdp-today-debc: rgba(134, 38, 51, 0.5);;
    --rmdp-hover-debc: rgba(134, 38, 51, 0.8);;
    --rmdp-deselect-debc: rgba(134, 38, 51, 0.3);;
}

.debc .rmdp-wrapper {
    border: 1px solid var(--rmdp-secondary-debc);
    box-shadow: 0 0 5px var(--rmdp-secondary-debc);
}

.debc .rmdp-panel-body li {
    background-color: var(--rmdp-primary-debc);
    box-shadow: 0 0 2px var(--rmdp-secondary-debc);
}

.debc .rmdp-week-day {
    color: var(--rmdp-primary-debc);
}

.debc .rmdp-day.rmdp-deactive {
    color: var(--rmdp-secondary-debc);
}

.debc .rmdp-range {
    background-color: var(--rmdp-primary-debc);
    box-shadow: 0 0 3px var(--rmdp-shadow-debc);
}

.debc .rmdp-arrow {
    border: solid var(--rmdp-primary-debc);
    border-width: 0 2px 2px 0;
}

.debc .rmdp-arrow-container:hover {
    background-color: var(--rmdp-primary-debc);
    box-shadow: 0 0 3px var(--rmdp-secondary-debc);
}

.debc .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: var(--rmdp-primary-debc) !important;
}

.debc .rmdp-day.rmdp-today span {
    background-color: var(--rmdp-today-debc);
}

.debc .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid var(--rmdp-secondary-debc);
}

.debc .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--rmdp-primary-debc);
    box-shadow: 0 0 3px var(--rmdp-shadow-debc);
}

.debc .rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-hover-debc) !important;
}

.debc .b-deselect {
    color: var(--rmdp-deselect-debc);
    background-color: white;
}

.debc .rmdp-action-button {
    color: var(--rmdp-primary-debc);
}

.debc .rmdp-button:not(.rmdp-action-button) {
    background-color: var(--rmdp-primary-debc);
}

.debc .rmdp-button:not(.rmdp-action-button):hover {
    background-color: var(--rmdp-deselect-debc);
}
