.message-received {
    max-width: 90%;
    margin: 3px 0;

    display: flex;
}

.message-received-text {
    padding: 12px 20px;
    min-height: 20px;
    background: #646464;
    border-radius: 1px 12px 12px 1px;
    text-align: left;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
}

.message-received-first {
    margin-top: 5px;
    border-radius: 12px 12px 12px 1px;
}

.message-received-last {
    margin-bottom: 5px;
    border-radius: 1px 12px 12px 12px;
}

.message-sent {
    max-width: 75%;
    display: flex;
    margin: 3px 0 3px auto;
}

.message-sent-text {
    border-radius: 12px 1px 1px 12px;
    min-height: 12px;
    margin-left: auto;
    text-align: right;
    padding: 12px 20px;
    color: black;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.20);
    background: #DEE2E6;
}

.message-sent-text a {
    color: #800022;
}

.message-sent-text a:hover {
    color: rgba(128, 0, 34, 0.75);
}

.message-sent-first {
    margin-top: 5px;
    border-radius: 12px 12px 1px 12px;
}

.message-sent-last {
    margin-bottom: 5px;
    border-radius: 12px 1px 12px 12px;
}

.message-edit-button {
    color: black;
    display: flex;
    padding: 5px;
    /*flex-direction: column-reverse;*/
    align-items: center;
}

.message-edit-button svg {
    cursor: pointer;
    fill: #373737;
}

.message-edit-button svg:hover {
    fill: #800022;
}