.chat-window {
    width: 400px;
    font-family: Roboto, sans-serif;
    position: absolute;
    bottom: 55px;
    right: 25px;
    opacity: 0; /* Initially fully transparent */
    transform: scale(0); /* Start slightly scaled down */
    transform-origin: bottom right;
    transition: scale 0s linear 0.2s, opacity 0.2s ease, transform 0.2s ease; /* Animate visibility delay, opacity, and transform */
    pointer-events: none; /* Initially not clickable */
    max-width: 440px;

    border: 1px solid rgba(0,0,0,0.01);
}

.chat-window-header {
    height: 88px;
    display: flex;
    border-radius: 20px 20px 0 0;
    background: #1e1e1e;
    align-items: center;
    justify-content: center;
}

.chat-window-header-bot-name {
    display: flex;
    align-items: center;
    gap: 9px;
}

.chat-window-header-bot-name-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 48px;
}

.bot-name {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.bot-status {
    color: #43EE7D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat-window-header-content {
    display: flex;
    width: 352px;
    height: 42px;
    min-height: 42px;
    justify-content: space-between;
    align-items: center;

    position: relative;
}

.chat-window-close-button {
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
}

.chat-window-open {
    visibility: visible; /* Make visible */
    opacity: 1; /* Fully opaque */
    transform: scale(1); /* Scale to normal size */
    pointer-events: all; /* Make clickable */
    transition-delay: 0s; /* Apply transition effect immediately */
}

.chat-window-closed {
    transform: scale(0); /* Scale to normal size */
}

.chat-orb {
    display: flex;
    position: absolute;
    width: 65px;
    height: 65px;
    background-color: #800022;
    color: #ffffff;
    border-radius: 50%;
    bottom: 55px;
    right: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    opacity: 1; /* Default opacity */
    transform: scale(1); /* Default scale */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Transition effect */
}

.chat-orb-visible {
    /*display: flex;*/
    opacity: 1;
    transform: scale(1); /* Normal size */
}

.chat-orb-hidden {
    opacity: 0;
    transform: scale(0.95); /* Slightly smaller */
    display: none; /* Ensures it doesn't block clicks */
}
.messages {
    max-height: 80vh;
    overflow: auto;
    display: flex;
    max-width: 400px;
    flex-direction: column;
    background-color: #F8F9FA;
    border-radius: 0 0 20px 20px;
    padding: 10px;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.messages a, .messages a:visited {
    color: white;
    text-decoration: underline;
}

.fade {
    height: 28px;
    margin: -10px -10px -8px -10px;
    background: #fff;
    background: linear-gradient(0deg, rgba(217, 217, 217, 0.00) 0%, rgba(0, 0, 0, 0.24) 100%);
}

.chat-commands-footer {
    margin: 16px -10px -10px -10px;
    padding: 10px;

    border-radius: 0px 0px 20px 20px;
    background: #FFF;
    box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.car-class-select {
    width: 100%;
}


@media (max-width: 600px) {
    .chat-window {
        width: 100%; /* Full width on mobile screens */
        right: 0; /* Align to the right edge */
        bottom: 55px; /* Align to the bottom edge */
        border-radius: 0; /* Remove border radius for full width */
    }

    .messages {
        max-width: 100%;
        right: 0;
        max-height: 70dvh;
    }
}