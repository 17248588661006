.svg-submit-button {
    position: absolute;
    top: 12px;
    right: 6px;
    cursor: pointer;
}

.driver-input-block-relative .driver-input {
    margin-top: 4px;
    line-height: 19px;
    font-size: 15px;
    color: #333;
}

.driver-input-block-relative {
    position: relative;
    width: 100%;
}

.skip-button:disabled {
    cursor: not-allowed;
    opacity: 50%;
}

.driver-input-block {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    justify-content: space-between;
    position: relative;
    width: 100%;
    gap: 8px;
}

.driver-input {
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    font-size: 15px;
}

.driver-suggestions {
    position: absolute;
    bottom: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    min-height: fit-content;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding-top: 2px;
}

.driver-suggestions-info {
    color: #666;
    font-size: 12px;
    padding: 0.5rem;
    margin: 0;
    border-bottom: 1px solid #ccc;
    text-align: right;
}

.driver-suggestions-info img {
    height: 10px;
}

.driver-suggestion {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px; /* Adjust the value to add space between the image and text */
    align-items: center;
    color: black;
    font-size: 16px;
    padding: 0.5rem;
    margin: 0;
    border-bottom: 1px solid #ccc;
    text-align: left;
    cursor: pointer;
    vertical-align: middle;
}

.driver-suggestion:hover {
    background-color: #f0f0f0;
}

.visible {
    display: block;
}

.hidden {
    display: none;
    width: 0;
}