.number-of-pax-input {
    line-height: 24px;
    font-size: 15px;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 64px;
    text-align: center;
}

.pax-number-input-block {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.pax-number-group {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.pax-number-spacer {
    width: 50%;
}

.pax-number-button:disabled {
    cursor: not-allowed;
}